import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import '@evag/ec-react/dist/main.css';
import CampaignForm from '../components/CampaignForm';
import Menu from '../components/Menu';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Iniciativas from "../components/Iniciativas";
import SocialNetworks from "../components/SocialNetworks";
import {GatsbyImage} from "gatsby-plugin-image";

export default function render({data}) {
    CampaignForm.channelId = data.site.meta.channel;

    return (
        <Layout>
            <SEO title={data.site.meta.title} ogImage={data.ogImage}/>


            <Menu
                logo={data.logo.childImageSharp.gatsbyImageData}
                items={[
                    {to: "/#manifesto", name: "Manifesto: Assine"},
                    {to: "/contribuicoes", name: "Contribuições"},
                    {to: "/iniciativas", name: "Iniciativas"},
                    {to: "/pressione", name: "Pressione"},
                ]}
                instagram={{
                    logo: data.instagram.childImageSharp.gatsbyImageData,
                    user: "solopretoeindigena"
                }}
                site={data.site.meta}
            />



            <Iniciativas
                background={data.iniciativas.childImageSharp.gatsbyImageData}
            />

            <footer className="footer">
                <div className="wrap">
                    <div className="description">
                        <div className="info">
                            <h1>Uma iniciativa do mandato da vereadora Luana Alves</h1>
                        </div>
                        <div className="social-networks">
                            <SocialNetworks
                                twitter="luanapsol"
                                facebook="luanapsol"
                                instagram="luanapsol"
                                youtube="channel/UClZyv3izFgsogtxHI1Z9Cxw"
                                email="comunicaluanaalves@gmail.com"
                            />
                        </div>
                    </div>
                    <div className="logos">
                        <GatsbyImage className="logo" alt="Punho - SP é Solo Preto!"
                             objectFit='contain'
                             style={{position: false}}
                             image={data.punho.childImageSharp.gatsbyImageData}/>
                    </div>
                </div>
            </footer>
        </Layout>
    );
}

render.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "SPSOLO-LOGO.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    
    manifesto: file(relativePath: { eq: "pankararu.png" }) {
      childImageSharp {
      gatsbyImageData(width: 1200)
      }
    }
    
    logo: file(relativePath: { eq: "pretoindigena.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        gatsbyImageData(width: 30)
      }
    }
    
    ogImage: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    
    iniciativas: file(relativePath: { eq: "iniciativas.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    
    formacao: file(relativePath: { eq: "curso.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    
    formacaoLogos: file(relativePath: { eq: "formacao-logos.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    
    punho: file(relativePath: { eq: "punho-footer.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    
    luana: file(relativePath: { eq: "apoiadores/luana alves.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    
    bpn: file(relativePath: { eq: "apoiadores/BPN.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    
    elaine: file(relativePath: { eq: "apoiadores/Elaine.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    geledes: file(relativePath: { eq: "apoiadores/geledés.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    ipeafro: file(relativePath: { eq: "apoiadores/IPEAFRO.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    marcha: file(relativePath: { eq: "apoiadores/marcha.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    mnu: file(relativePath: { eq: "apoiadores/MNU.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    sarauPovo: file(relativePath: { eq: "apoiadores/Saraupovo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    silvioDeAlmeida: file(relativePath: { eq: "apoiadores/silvio almeida.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    abilio: file(relativePath: { eq: "apoiadores/abilio.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    baby: file(relativePath: { eq: "apoiadores/baby.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    chirley: file(relativePath: { eq: "apoiadores/chirley.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    claricePankararu: file(relativePath: { eq: "apoiadores/clarice pankararu.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    erika: file(relativePath: { eq: "apoiadores/erika.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    jairo: file(relativePath: { eq: "apoiadores/jairo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    janja: file(relativePath: { eq: "apoiadores/janja.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    milton: file(relativePath: { eq: "apoiadores/milton.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    emancipa: file(relativePath: { eq: "apoiadores/rede emancipa.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    tiao: file(relativePath: { eq: "apoiadores/mestre tiao carvalho.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    dinho: file(relativePath: { eq: "apoiadores/mestre dinho.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    herzog: file(relativePath: { eq: "apoiadores/herzog.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    snp: file(relativePath: { eq: "apoiadores/coletivo SNP.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    feminine: file(relativePath: { eq: "apoiadores/feminine hifi.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    ras: file(relativePath: { eq: "apoiadores/PORTAL RAS.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    cartografia: file(relativePath: { eq: "apoiadores/cartografia negra.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    anatorg: file(relativePath: { eq: "apoiadores/anatorg.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    regou: file(relativePath: { eq: "apoiadores/ja regou.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
  }
`;
